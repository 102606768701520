<template>
  <v-icon v-if="header.useIcons" :color="color" v-text="icon" />
  <span v-else v-text="label" />
</template>

<script>
import { TableHeaderSwitch } from 'Lib/TypeDefs';

export default {
  name: 'CellSwitch',
  props: {
    header: {
      type: TableHeaderSwitch,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    state() {
      return this.item[this.header.prop] || false;
    },
    icon() {
      const { iconFalse, iconTrue } = this.header;
      return this.state ? iconTrue || 'mdi-checkbox-marked-outline' : iconFalse || 'mdi-checkbox-blank-outline';
    },
    color() {
      const { colorFalse, colorTrue } = this.header;
      return this.state ? colorTrue || 'success' : colorFalse || 'grey lighten-1';
    },
    label() {
      const { labelFalse, labelTrue } = this.header;
      return this.state ? labelTrue || 'Yes' : labelFalse || 'No';
    },
  },
};
</script>
