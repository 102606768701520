<template>
  <v-container>
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-12">
        <domains-counter />
      </div>
    </div>
  </v-container>
</template>

<script>
import DomainsCounter from 'Components/DomainsCounter';

export default {
  name: 'Home',
  components: { DomainsCounter },
};
</script>

<style scoped></style>
