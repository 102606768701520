<template>
  <v-simple-table fixed-header>
    <thead>
      <tr>
        <th v-for="(header, i) in headers" :key="i">
          {{ header.text || '' }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, i) in items" :key="i">
        <td v-for="(hdr, j) in headers" :key="j">
          <cell v-bind="{ item, header: hdr }" />
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import Cell from 'Components/TableCells/Cell';
export default {
  name: 'SimpleTable',
  components: { Cell },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
