import BaseModel from 'Models/BaseModel';
import { MODULE_INFORMATION_SOURCES } from 'Lib/enums/ApiModules';
import SearchModel from 'Models/SearchModel';
import FilterTypes from 'Components/Search/FilterTypes';
import FieldTypes from 'Components/Search/FieldTypes';
import { DataTableHeader, TableHeaderActions, TableHeaderName, TableHeaderPopupTable } from 'Lib/TypeDefs';
import { HDR_SIMPLE_TABLE } from 'Lib/enums/DataTableHeaders';
import { WIMS_URL } from '../../project.config';

/**
 * @class InformationSource
 * @property {number} id
 * @property {string} url
 * @property {number} numberOfSnapshots
 * @property {Array<{ id: number, name: string }>} products
 */
export default class InformationSource extends BaseModel {
  static MODULE = MODULE_INFORMATION_SOURCES;
  _defaults = {
    products: [],
  };

  /**
   * @type {SearchModel}
   */
  static searchConf = new SearchModel(
    [
      {
        id: 'url',
        title: 'URL',
        filter: FilterTypes.FILTER_SINGLE,
        type: FieldTypes.FIELD_TEXT,
      },
      {
        id: 'numberOfSnapshots',
        title: 'Number Of Snapshots',
        filter: FilterTypes.FILTER_SINGLE,
        type: FieldTypes.FIELD_NUMBER,
      },
    ],
    'url'
  );

  static getHeaders = () => {
    return [
      new DataTableHeader({
        text: 'ID',
        value: 'id',
        width: '1%',
        sortable: true,
      }),
      new TableHeaderName({
        text: 'URL',
        prop: 'url',
        sortable: true,
      }),
      new DataTableHeader({
        text: 'Number Of Snapshots',
        value: 'numberOfSnapshots',
      }),
      new TableHeaderPopupTable({
        text: 'Products',
        prop: 'products',
        headers: [
          ...HDR_SIMPLE_TABLE,
          new TableHeaderActions({
            text: '',
            actions: ['openUrl'],
            width: '1%',
            cellClass: 'no-wrap',
            actionsData: {
              openUrl: {
                icon: 'mdi-open-in-new',
                color: 'info',
                hint: 'Open URL in new tab',
                callback: (item) => {
                  window.open(`${WIMS_URL}/products/${item.id}`, '_blank');
                },
              },
            },
          }),
        ],
      }),
      new TableHeaderActions({
        text: '',
        actions: ['openUrl'],
        width: '1%',
        cellClass: 'no-wrap',
        actionsData: {
          openUrl: {
            icon: 'mdi-open-in-new',
            color: 'info',
            hint: 'Open URL in new tab',
            callback: (item) => {
              window.open(item.url, '_blank');
            },
          },
        },
      }),
    ];
  };

  static getDefaultSort = () => ({ by: 'id', desc: true });
}
