<template>
  <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="innerValue"
        prepend-icon="mdi-calendar"
        v-bind="mixAttrs(attrs, inputAttrs)"
        v-on="on"
        hide-details
        clearable
        dense
      />
    </template>
    <template>
      <v-date-picker v-model="innerValue" v-bind="pickerAttrs" @input="menu = false" />
    </template>
  </v-menu>
</template>

<script>
export default {
  name: 'InputDate',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      required: true,
    },
    label: String,
    inputProps: {
      type: Object,
      default: () => ({}),
    },
    pickerProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    inputAttrs() {
      return {
        hideDetails: true,
        clearable: true,
        dense: true,
        label: this.label,
        ...(this.inputProps || {}),
      };
    },
    pickerAttrs() {
      return {
        noTitle: true,
        scrollable: true,
        ...(this.pickerProps || {}),
      };
    },
    innerValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('change', value);
      },
    },
  },
  methods: {
    mixAttrs(...mixins) {
      // head priority
      return mixins.reduce((acc, cur) => ({ ...(cur || {}), ...acc }), {});
    },
  },
};
</script>
