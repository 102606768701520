import NewsletterEmail from 'Models/NewsletterEmail';
import Modules from 'Lib/enums/ApiModules';
import NewsletterEmailDomain from 'Models/NewsletterEmailDomain';
import InformationSource from 'Models/InformationSource';
import InformationSourceSnapshot from 'Models/InformationSourceSnapshot';
import NewsPost from 'Models/NewsPost';

export const MODULES_MAP = {
  [Modules.MODULE_NEWSLETTER_EMAILS]: {
    model: NewsletterEmail,
    title: 'Newsletter Emails',
    icon: 'mdi-email',
  },
  [Modules.MODULE_NEWSLETTER_EMAILS_DOMAIN]: {
    model: NewsletterEmailDomain,
    title: 'Newsletter Email Domains',
    menuTitle: 'Domains',
    icon: 'mdi-domain',
    endpoint: {
      list: '/api/newsletter_email/domains',
    },
    noNav: true,
  },
  [Modules.MODULE_INFORMATION_SOURCES]: {
    model: InformationSource,
    title: 'Product URL sources cached',
    icon: 'mdi-book-information-variant',
    endpoint: {
      list: '/api/information_sources',
    },
  },
  [Modules.MODULE_INFORMATION_SOURCE_SNAPSHOTS]: {
    model: InformationSourceSnapshot,
    title: 'Product URL sources snapshots',
    endpoint: {
      list: '/api/information_source_snapshots',
    },
    noNav: true,
    excludeFromMenu: true,
  },
  [Modules.MODULE_NEWS]: {
    model: NewsPost,
    title: 'News',
    icon: 'mdi-post',
  },
};
