export const FIELD_TEXT = 'text';
export const FIELD_NUMBER = 'number';
export const FIELD_AUTOCOMPLETE = 'autocomplete';
export const FIELD_SELECT = 'select';
export const FIELD_DATE = 'date';
export const FIELD_SWITCH = 'switch';

export default {
  FIELD_TEXT,
  FIELD_NUMBER,
  FIELD_AUTOCOMPLETE,
  FIELD_DATE,
  FIELD_SELECT,
  FIELD_SWITCH,
};
