<template>
  <v-container>
    <component v-if="item && component" :is="component" v-bind="{ item }" />
    <div v-if="item && !component">
      <simple-table v-if="props.length" v-bind="infoTableProps" />
    </div>
  </v-container>
</template>

<script>
import { MODULES_MAP } from 'Lib/enums/ApiModulesMap';
import NewsletterEmail from 'Components/ItemsViews/NewsletterEmail';
import InformationSource from 'Components/ItemsViews/InformationSource';
import NewsPost from 'Components/ItemsViews/NewsPost';
import { getProp } from 'Lib/Utils';
import moment from 'moment-timezone';
import SimpleTable from 'Components/SimpleTable';
import { DataTableHeader, TableHeaderName } from 'Lib/TypeDefs';

export default {
  name: 'ModuleItem',
  components: { SimpleTable, InformationSource, NewsletterEmail, NewsPost },
  data() {
    return {
      item: null,
      loading: false,
    };
  },
  computed: {
    module() {
      return this.$route.params.module;
    },
    id() {
      return this.$route.params.id;
    },
    moduleConf() {
      return MODULES_MAP[this.module];
    },
    title() {
      return this.moduleConf?.title || `${this.module}: ${this.id}`;
    },
    component() {
      const { module, Modules } = this;
      return (
        {
          [Modules.MODULE_NEWSLETTER_EMAILS]: 'NewsletterEmail',
          [Modules.MODULE_INFORMATION_SOURCES]: 'InformationSource',
          [Modules.MODULE_NEWS]: 'NewsPost',
        }[module] || null
      );
    },
    props() {
      const { item } = this;
      if (typeof item.getPropsList === 'function') {
        return item.getPropsList(item) || [];
      }
      return [];
    },
    infoTableProps() {
      const { item, props } = this;
      const items = Array(props.length);
      props.forEach((prop, i) => {
        items[i] = { label: prop.label, value: this.formatValue(prop, item) };
      });
      return {
        items,
        headers: [new TableHeaderName({ prop: 'label' }), new DataTableHeader({ value: 'value' })],
      };
    },
  },
  watch: {
    $route() {
      this.getItem();
    },
  },
  created() {
    this.getItem();
  },
  methods: {
    async getItem() {
      this.item = null;
      this.loading = true;
      this.item = await this.$api.getApiItem(this.module, this.id).catch(() => {
        this.loading = false;
      });
      this.loading = false;
    },
    formatValue(prop) {
      const { id, type, default: defVal } = prop;
      const value = getProp(this.item, id) || defVal || null;
      if (type === 'switch') {
        return ['No', 'Yes'][+!!value];
      }
      if (type === 'unix_timestamp') {
        const { format } = prop;
        const { timezone } = this.item;
        const time = moment.unix(value);
        if (timezone && !/utc|gmt/i.test(timezone)) {
          time.tz(timezone);
        }
        return time.format(format || 'HH:mm, MMMM DD, YYYY');
      }
      return typeof value === 'undefined' ? '(not set)' : value;
    },
  },
};
</script>
