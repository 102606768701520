<template>
  <component :is="component" v-model="innerValue" :config="config" />
</template>

<script>
import FilterTypes from 'Components/Search/FilterTypes';
import FieldSingle from 'Components/Search/FieldSingle';
import FieldRange from 'Components/Search/FieldRange';
import FieldList from 'Components/Search/FieldList';

export default {
  name: 'FilterEntry',
  components: { FieldList, FieldRange, FieldSingle },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {},
    config: {
      type: Object,
      required: true,
    },
  },
  computed: {
    component() {
      return {
        [FilterTypes.FILTER_SINGLE]: 'field-single',
        [FilterTypes.FILTER_RANGE]: 'field-range',
        [FilterTypes.FILTER_LIST]: 'field-list',
      }[this.config.filter];
    },
    innerValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('change', value);
      },
    },
  },
};
</script>
