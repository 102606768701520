import BaseModel from 'Models/BaseModel';
import { MODULE_NEWS } from 'Lib/enums/ApiModules';
import SearchModel from 'Models/SearchModel';
import FilterTypes from 'Components/Search/FilterTypes';
import FieldTypes from 'Components/Search/FieldTypes';
import { DataTableHeader, TableHeaderDate, TableHeaderName, TableHeaderSwitch } from 'Lib/TypeDefs';

/**
 * @typedef {Object} NewsPostImage
 * @property {number} id
 * @property {string} contentUrl
 * @property {boolean} intro
 */

/**
 * @class NewsPost
 * @property {number} id
 * @property {string} name
 * @property {string} slug
 * @property {string} introText
 * @property {string} fullText
 * @property {string} publishedAt
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {boolean} published
 * @property {NewsPostImage[]} images
 */
export default class NewsPost extends BaseModel {
  static MODULE = MODULE_NEWS;
  _defaults = {
    images: [],
  };

  /**
   * @type {SearchModel}
   */
  static searchConf = new SearchModel(
    [
      {
        id: 'id',
        title: 'ID',
        filter: FilterTypes.FILTER_SINGLE,
        type: FieldTypes.FIELD_NUMBER,
      },
      {
        id: 'name',
        title: 'Title',
        filter: FilterTypes.FILTER_SINGLE,
        type: FieldTypes.FIELD_TEXT,
      },
      {
        id: 'publishedAt',
        title: 'Publish Date',
        filter: FilterTypes.FILTER_RANGE,
        type: FieldTypes.FIELD_DATE,
        rangeConf: {
          from: {
            id: 'publishedAt[after]',
            strictId: 'publishedAt[strictly_after]',
          },
          to: {
            id: 'publishedAt[before]',
            strictId: 'publishedAt[strictly_before]',
          },
        },
      },
      {
        id: 'published',
        label: 'Published',
        title: 'Published',
        filter: FilterTypes.FILTER_SINGLE,
        type: FieldTypes.FIELD_SWITCH,
      },
    ],
    'name'
  );

  static getHeaders = () => {
    return [
      new DataTableHeader({
        text: 'ID',
        value: 'id',
        width: '1%',
        sortable: true,
      }),
      new TableHeaderName({
        text: 'Title',
        prop: 'name',
      }),
      new TableHeaderSwitch({
        text: 'Published',
        prop: 'published',
        useIcons: true,
      }),
      new TableHeaderDate({
        text: 'Publish Date',
        prop: 'publishedAt',
        sortable: true,
      }),
      new TableHeaderDate({
        text: 'Creation Date',
        prop: 'createdAt',
      }),
      new TableHeaderDate({
        text: 'Modifying Date',
        prop: 'updatedAt',
      }),
    ];
  };

  static getDefaultSort = () => ({ by: 'id', desc: true });
}
