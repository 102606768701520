<template>
  <h1 class="headline">{{ title }}</h1>
</template>

<script>
import { MODULES_MAP } from 'Lib/enums/ApiModulesMap';

export default {
  name: 'MainTitle',
  computed: {
    title() {
      const { path, params } = this.$route;
      if (/^\/modules\//i.test(path)) {
        return this.getModuleTitle(params.module);
      }
      if (!path || path === '/') {
        return 'Homepage';
      }
      return '';
    },
  },
  methods: {
    getModuleTitle(module) {
      return MODULES_MAP[module]?.title || '';
    },
  },
};
</script>
