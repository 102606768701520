import Modules from 'Lib/enums/ApiModules';

export default {
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    Modules() {
      return { ...Modules };
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch('logout');
    },
    async logIn({ token, refresh_token }) {
      this.$http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const user = await this.$api.getUserProfile();
      if (!user.groups.some((el) => el.name === 'ROLE_STAFF')) {
        throw new Error('Access denied');
      }
      this.loading = false;
      this.$store
        .dispatch('login', { success: !!token, token, refresh_token, user })
        .then(() => {
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect).catch();
          }
        })
        .catch((err) => console.error(err));
    },
  },
};
