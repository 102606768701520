<template>
  <div style="display: flex; flex-wrap: nowrap; align-items: center; justify-content: flex-start">
    <div class="short-name-wrapper" :title="value">
      <span class="short-name">{{ value || header.placeholder }}</span>
    </div>
  </div>
</template>

<script>
import { TableHeaderName } from 'Lib/TypeDefs';

export default {
  name: 'CellName',
  props: {
    item: Object,
    header: TableHeaderName,
  },
  computed: {
    value() {
      return this.item[this.header.prop || 'name'] || null;
    },
  },
};
</script>
