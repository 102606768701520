<template>
  <v-btn-toggle v-model="state">
    <v-btn outlined small>Any</v-btn>
    <v-btn outlined color="red" small>No</v-btn>
    <v-btn outlined color="green" small>Yes</v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: 'FilterSwitch',
  props: {
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
  },
  data() {
    return {
      id: 0,
    };
  },
  computed: {
    valuesList() {
      return [null, 0, 1];
    },
    state: {
      get() {
        return this.valuesList.indexOf(this.value);
      },
      set(id) {
        const val = [null, false, true][id];
        this.$emit('input', val);
        this.id = id;
        return true;
      },
    },
  },
  methods: {
    // onChange(state) {},
  },
};
</script>
