/**
 * @class {DataTableHeader}
 * @property {string} text
 * @property {string} value
 * @property {string} [align] 'start' | 'center' | 'end'
 * @property {boolean} [sortable]
 * @property {boolean} [filterable]
 * @property {boolean} [groupable]
 * @property {boolean} [divider]
 * @property {string|string[]} [class]
 * @property {string|string[]} [cellClass]
 * @property {string|number} [width]
 * @property {function} [filter] (value: any, search: string, item: any) => boolean
 * @property {function} [sort] (a: any, b: any) => number
 */
export class DataTableHeader {
  sortable = false;

  constructor(props = {}) {
    Object.assign(this, props);
  }
}

/**
 * @class TableHeaderSwitch
 * @extends DataTableHeader
 * @property {string} text
 * @property {string} value
 * @property {string} [align] 'start' | 'center' | 'end'
 * @property {boolean} [sortable]
 * @property {boolean} [filterable]
 * @property {boolean} [groupable]
 * @property {boolean} [divider]
 * @property {string|string[]} [class]
 * @property {string|string[]} [cellClass]
 * @property {string|number} [width]
 * @property {function} [filter] (value: any, search: string, item: any) => boolean
 * @property {function} [sort] (a: any, b: any) => number
 * @property {string} [prop]
 * @property {boolean} [useIcons]
 * @property {string} [iconFalse]
 * @property {string} [iconTrue]
 * @property {string} [colorFalse]
 * @property {string} [colorTrue]
 * @property {string} [labelFalse]
 * @property {string} [labelTrue]
 */
export class TableHeaderSwitch extends DataTableHeader {
  constructor(props) {
    super({ ...props, value: '__switch' });
  }
}

/**
 * @class TableHeaderName
 * @extends DataTableHeader
 * @property {string} text
 * @property {string} value
 * @property {string} [align] 'start' | 'center' | 'end'
 * @property {boolean} [sortable]
 * @property {boolean} [filterable]
 * @property {boolean} [groupable]
 * @property {boolean} [divider]
 * @property {string|string[]} [class]
 * @property {string|string[]} [cellClass]
 * @property {string|number} [width]
 * @property {function} [filter] (value: any, search: string, item: any) => boolean
 * @property {function} [sort] (a: any, b: any) => number
 * @property {string} [prop]
 * @property {string} [placeholder]
 */
export class TableHeaderName extends DataTableHeader {
  constructor(props) {
    super({ ...props, value: '__name' });
  }
}

/**
 * @class TableHeaderActions
 * @extends DataTableHeader
 * @property {string} text
 * @property {string} value
 * @property {string} [align] 'start' | 'center' | 'end'
 * @property {boolean} [sortable]
 * @property {boolean} [filterable]
 * @property {boolean} [groupable]
 * @property {boolean} [divider]
 * @property {string|string[]} [class]
 * @property {string|string[]} [cellClass]
 * @property {string|number} [width]
 * @property {function} [filter] (value: any, search: string, item: any) => boolean
 * @property {function} [sort] (a: any, b: any) => number
 * @property {string[]} actions
 * @property {Object} [actionsData]
 */
export class TableHeaderActions extends DataTableHeader {
  constructor(props) {
    super({ ...props, value: '__actions' });
  }
}

/**
 * @class TableHeaderDate
 * @extends DataTableHeader
 * @property {string} text
 * @property {string} value
 * @property {string} [align] 'start' | 'center' | 'end'
 * @property {boolean} [sortable]
 * @property {boolean} [filterable]
 * @property {boolean} [groupable]
 * @property {boolean} [divider]
 * @property {string|string[]} [class]
 * @property {string|string[]} [cellClass]
 * @property {string|number} [width]
 * @property {function} [filter] (value: any, search: string, item: any) => boolean
 * @property {function} [sort] (a: any, b: any) => number
 * @property {string} [prop]
 * @property {string} [format]
 * @property {string} [placeholder]
 */
export class TableHeaderDate extends DataTableHeader {
  constructor(props) {
    super({ ...props, value: '__date' });
  }
}

/**
 * @class TableHeaderPopupTable
 * @extends DataTableHeader
 * @property {string} text
 * @property {string} value
 * @property {string} [align] 'start' | 'center' | 'end'
 * @property {boolean} [sortable]
 * @property {boolean} [filterable]
 * @property {boolean} [groupable]
 * @property {boolean} [divider]
 * @property {string|string[]} [class]
 * @property {string|string[]} [cellClass]
 * @property {string|number} [width]
 * @property {function} [filter] (value: any, search: string, item: any) => boolean
 * @property {function} [sort] (a: any, b: any) => number
 * @property {string} [prop]
 * @property {Array<Object>} [items]
 * @property {Array<Object>} [headers]
 */
export class TableHeaderPopupTable extends DataTableHeader {
  constructor(props) {
    super({ ...props, value: '__popupTable' });
  }
}
