<template>
  <div class="d-flex" style="column-gap: 10px">
    <field-single v-model="vFrom" :config="confFrom" />
    <field-single v-model="vTo" :config="confTo" />
  </div>
</template>

<script>
import FieldSingle from 'Components/Search/FieldSingle';
export default {
  name: 'FieldRange',
  components: { FieldSingle },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {},
    config: {
      type: Object,
      required: true,
    },
  },
  computed: {
    confFrom() {
      return {
        ...this.commonConf,
        id: 'from',
        label: 'From',
      };
    },
    confTo() {
      return {
        ...this.commonConf,
        id: 'to',
        label: 'To',
      };
    },
    commonConf() {
      // eslint-disable-next-line no-unused-vars
      const { id, label, ...rest } = this.config;
      return { ...rest };
    },
    vFrom: {
      get() {
        return this.value?.from || null;
      },
      set(value) {
        this.$emit('change', { from: value, to: this.vTo });
      },
    },
    vTo: {
      get() {
        return this.value?.to || null;
      },
      set(value) {
        this.$emit('change', { from: this.vFrom, to: value });
      },
    },
  },
};
</script>
