<template>
  <div v-if="item">
    <div class="text-h5">{{ item.subject }}</div>
    <div class="d-flex justify-space-between text--secondary py-1">
      <span>{{ item.domain }}</span>
      <span>{{ item.dateReceived | asDateTime }}</span>
    </div>
    <iframe :srcdoc="item.bodies[0].text" class="email-body" />
    <div class="text-h6">Files:</div>
    <v-list v-if="item.files.length" outlined elevation="3">
      <v-list-item v-for="file in item.files" :key="file.id">
        <v-list-item-title>{{ file.originalName }}</v-list-item-title>
        <v-list-item-action>
          <v-btn :href="file.downloadUrl" :download="file.originalName" text title="Download">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import NewsletterEmail from 'Models/NewsletterEmail';

export default {
  name: 'NewsletterEmail',
  props: {
    item: NewsletterEmail,
  },
};
</script>

<style scoped lang="scss">
iframe.email-body {
  width: 100%;
  height: 80vh;
}
</style>
