import Api from 'Lib/Api';
import { MODULES_MAP } from 'Lib/enums/ApiModulesMap';
import { format } from 'Lib/Utils';

export default class ApiMapper {
  /**
   * @type {Api}
   */
  #api;

  constructor(api, modulesMap) {
    if (!(api instanceof Api)) {
      throw new Error('"api" must be instance of Api');
    }
    this.#api = api;
    this.modulesMap = modulesMap;
  }

  async getUserProfile() {
    return this.#api.getItem('/api/users/profile', { options: { re: true } });
  }

  async updateProfile(data = {}) {
    const res = await this.#api.patch('/api/users/profile', { data });
    if (res.status === 400) {
      throw new Error('Invalid input');
    }
    if (res.status === 404) {
      throw new Error('Resource not found');
    }
    return res.data;
  }

  async getApiCollection(module, params) {
    const { model } = this.modulesMap[module] || { model: null };
    const ld = model?.LD || false;
    if (!ld) {
      delete params.page;
      delete params.itemsPerPage;
    }
    const config = { model, options: { params, re: true, ld } };
    return this.#api.getItemsCollection(this.getModuleListUrl(module), config);
  }

  async getApiItem(module, id) {
    const { model } = this.modulesMap[module] || { model: null };
    const config = { model, options: { re: true, ld: false } };
    return this.#api.getItem(this.getModuleItemUrl(module, id), config);
  }

  async getNewsletterEmailDomainsAmount() {
    return this.#api.get('/api/newsletter_email/domains/total_items').then((res) => {
      if (res.status === 200) {
        return res.data?.total || null;
      }
      return null;
    });
  }

  getModuleListUrl(module) {
    const conf = MODULES_MAP[module];
    return conf?.endpoint?.list || `/api/${module}`;
  }

  getModuleItemUrl(module, id) {
    const conf = MODULES_MAP[module];
    const tpl = conf?.endpoint?.item || `/api/${module}/{id}`;
    return format(tpl, { id });
  }
  //
  // async getNewsletterEmailDomainsList(params) {
  //   return this.#api.get('/api/newsletter_email/domains/total_items', { params });
  // }
}
