<template>
  <v-card class="counter-card" :to="to">
    <v-progress-linear indeterminate :active="loading" color="blue" absolute top />
    <v-card-text class="counter-card-content">
      <div class="counter-icon">
        <v-icon size="64">mdi-wan</v-icon>
      </div>
      <div class="counter-data">
        <span class="counter-value">{{ amount || '--' }}</span>
        <span class="counter-title">Newsletters Domains</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import CountersMixin from 'Mixins/CountersMixin';
import { MODULE_NEWSLETTER_EMAILS_DOMAIN } from 'Lib/enums/ApiModules';

const COUNTER_KEY = 'newsletter-domains';

export default {
  name: 'DomainsCounter',
  mixins: [CountersMixin],
  data() {
    return {
      amount: null,
      loading: false,
      to: { name: 'ModuleListing', params: { module: MODULE_NEWSLETTER_EMAILS_DOMAIN } },
    };
  },
  created() {
    const val = this.getCounter(COUNTER_KEY) || {};
    if (typeof val === 'number') {
      this.fetchAmount();
    } else {
      const { value, updatedAt } = val;
      this.amount = value;
      const isOutdated = ~~(Date.now() / 1000) - updatedAt > 600;
      if (value === null || isOutdated) {
        this.fetchAmount();
      }
    }
  },
  methods: {
    async fetchAmount() {
      this.loading = true;
      this.amount = await this.$api.getNewsletterEmailDomainsAmount();
      this.loading = false;
      this.setCounter(COUNTER_KEY, this.amount);
    },
  },
};
</script>

<style scoped lang="scss">
.counter-card {
  padding: 10px;
  max-width: 280px;

  .counter-card-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    .counter-icon {
    }

    .counter-data {
      display: flex;
      flex-direction: column;

      .counter-title {
        padding: 0;
        text-align: center;
        justify-content: center;
        color: gray;
      }

      .counter-value {
        padding: 0;
        font-size: 3rem;
        line-height: 3rem;
        text-align: center;
        color: #2196f3 !important;
      }
    }
  }
}
</style>
