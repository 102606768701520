import Vue from 'vue';
import Vuex from 'vuex';
import { persist, restore } from 'Lib/Utils';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: '',
    token: restore('token', ''),
    refresh_token: restore('refresh_token', ''),
    user: restore('token', {}, true),
    filters: restore('filters', {}, true),
    loader: false,
    breadcrumbs: [],
    counters: restore('counters', {}, true),
  },
  mutations: {
    authSuccess(state, { token, refresh_token }) {
      state.status = 'success';
      state.token = token;
      state.refresh_token = refresh_token;
      persist('token', token);
      persist('refresh_token', refresh_token);
    },
    authError(state) {
      state.status = 'error';
    },
    logout(state) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('filters');
      state.status = '';
      state.token = '';
      state.refresh_token = '';
      state.user = {};
      state.filters = {};
    },
    setUser(state, value) {
      state.user = value || {};
      persist('user', state.user, true);
    },
    setLoader(state, value) {
      state.loader = value;
    },
    setBreadcrumbs(state, data) {
      state.breadcrumbs = data;
    },
    clearRefreshToken(state) {
      state.refresh_token = '';
      localStorage.removeItem('refresh_token');
    },
    setFilters(state, { module, filters }) {
      state.filters = { ...state.filters, [module]: filters };
      persist('filters', state.filters, true);
    },
    setCounter(state, { key, value }) {
      state.counters = { ...state.counters, [key]: { value, updatedAt: ~~(Date.now() / 1000) } };
      persist('counters', state.counters, true);
    },
  },
  actions: {
    login({ commit }, { success, token, refresh_token, user }) {
      if (success && user.groups?.some((el) => el.name === 'ROLE_STAFF')) {
        commit('authSuccess', { token, refresh_token });
        commit('setUser', user);
      } else {
        commit('authError');
      }
    },
    logout({ commit }) {
      commit('logout');
    },
    setUser({ commit }, value) {
      commit('setUser', value);
    },
    loader({ commit }, value) {
      commit('setLoader', !!value);
    },
    setBreadcrumbs({ commit }, data) {
      commit('setBreadcrumbs', data);
    },
    checkAuthToken() {},
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    isLoading: (state) => state.loader,
    userData: (state) => {
      const { name, username, email, registerDate, lastVisitDate, phone, company } = state.user;
      return {
        name,
        username,
        email,
        registerDate,
        lastVisitDate,
        phone,
        company,
      };
    },
  },
});
