import Vue from 'vue';
import { formatDateTime } from 'Lib/Utils';

Vue.filter('asDateTime', formatDateTime);

Vue.filter('asDate', (value) => formatDateTime(value, 'YYYY-MM-DD'));

Vue.filter('asTime', (value) => formatDateTime(value, 'HH:mm:ss'));

Vue.filter('asShortTime', (value) => formatDateTime(value, 'HH:mm'));
