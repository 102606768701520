<template>
  <div style="display: flex; flex-wrap: nowrap; align-items: center; justify-content: flex-start" @click.stop>
    <v-menu-transition>
      <v-menu max-width="500" min-width="300" max-height="400" bottom offset-y>
        <template #activator="{ attrs, on }">
          <v-btn icon plain v-bind="attrs" v-on="on" :disabled="listSize === 0">
            {{ listSize }}
            <v-icon>mdi-view-list</v-icon>
          </v-btn>
        </template>
        <template>
          <simple-table :headers="header.headers" :items="value" />
        </template>
      </v-menu>
    </v-menu-transition>
  </div>
</template>

<script>
import { TableHeaderPopupTable } from 'Lib/TypeDefs';
import SimpleTable from 'Components/SimpleTable';

export default {
  name: 'CellPopupTable',
  components: { SimpleTable },
  props: {
    item: Object,
    header: TableHeaderPopupTable,
  },
  computed: {
    value() {
      return this.item[this.header.prop || 'items'] || [];
    },
    listSize() {
      return this.value?.length || 0;
    },
  },
};
</script>
