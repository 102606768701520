<template>
  <v-container style="height: 100%; display: flex; align-items: center; justify-content: center">
    <LoginForm v-if="form === 'login'" />
  </v-container>
</template>

<script>
import LoginForm from 'Components/LoginForm';

export default {
  name: 'Auth',
  components: { LoginForm },
  props: {
    form: {
      validator: (val) => ['register', 'login'].includes(val),
      default: 'login',
    },
  },
};
</script>
