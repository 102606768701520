import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from 'Views/Home';
import Auth from 'Views/Auth';
import store from '../store';
import { isJWTExpired } from 'Lib/Utils';
import ModuleListing from 'Views/ModuleListing';
import ModuleItem from 'Views/ModuleItem';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    components: {
      default: Home,
      public: Auth,
    },
    props: {
      form: 'login',
    },
  },
  {
    path: '/modules/:module/:id',
    name: 'ModuleItem',
    component: ModuleItem,
  },
  {
    path: '/modules/:module',
    name: 'ModuleListing',
    component: ModuleListing,
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (store.state.token && isJWTExpired(store.state.token)) {
    store.dispatch(store.state.refresh_token ? 'checkAuthToken' : 'logout').then(() => {
      next('/');
    });
  }
  next();
});

export default router;
