<template>
  <div v-if="item">
    <v-row>
      <v-col cols="12" md="4">
        <v-card class="mx-auto" max-width="374">
          <v-img height="250" v-if="introImage" :src="introImage.contentUrl" />
          <v-card-title style="word-break: break-word">{{ item.name }}</v-card-title>
          <v-card-subtitle>{{ item.publishedAt | asDate }}</v-card-subtitle>
          <v-card-text>{{ item.introText }}</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn :href="wimsUrl" target="_blank" color="info" outlined right>
              View in WIMS
              <v-icon class="ml-2">mdi-open-in-new</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="8">
        <v-card>
          <v-card-title style="word-break: break-word">{{ item.name }}</v-card-title>
          <v-card-subtitle>{{ item.publishedAt | asDate }}</v-card-subtitle>
          <v-card-text>
            <v-carousel v-if="otherImages.length > 0" cycle height="250" show-arrows-on-hover hide-delimiters>
              <v-carousel-item v-for="(img, i) in otherImages" :key="i">
                <v-sheet tile class="" color="blue-grey lighten-2" height="100%">
                  <v-row class="fill-height" align="center" justify="center">
                    <v-img :src="img.contentUrl" max-height="250" contain />
                  </v-row>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
            <div v-html="item.fullText" />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn :href="wimsUrl" target="_blank" color="info" outlined right>
              View in WIMS
              <v-icon class="ml-2">mdi-open-in-new</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="12">
        <v-divider />
        <v-simple-table>
          <tbody>
            <tr>
              <td>Created At</td>
              <td>{{ item.createdAt | asDateTime }}</td>
            </tr>
            <tr>
              <td>Updated At</td>
              <td>{{ item.updatedAt | asDateTime }}</td>
            </tr>
            <tr>
              <td>Published At</td>
              <td>{{ item.publishedAt | asDateTime }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Post from 'Models/NewsPost';
import { WIMS_URL } from '../../../project.config';

export default {
  name: 'NewsPost',
  props: {
    item: Post,
  },
  computed: {
    introImage() {
      return this.item.images.find((el) => !!el.intro);
    },
    otherImages() {
      return this.item.images.filter((el) => !el.intro);
    },
    wimsUrl() {
      return `${WIMS_URL}/news/${this.item.id}`;
    },
  },
};
</script>
