<!--suppress HtmlUnknownAttribute -->
<template>
  <div>
    <h2 class="headline text-center">Product URL source</h2>
    <item-info-table v-bind="{ item, module }" />
    <v-card elevation="4" class="mt-6">
      <v-card-title>Snapshots ({{ item.numberOfSnapshots }})</v-card-title>
      <v-card-text>
        <listing-table
          v-if="item.numberOfSnapshots > 0"
          :module="Modules.MODULE_INFORMATION_SOURCE_SNAPSHOTS"
          disable-search
          :permanent-filters="snapshotsFilter"
        />
        <div v-else>There are no snapshots for this source yet.</div>
      </v-card-text>
    </v-card>
    <v-card elevation="4" class="mt-6">
      <v-card-title>Related products ({{ item.products.length }})</v-card-title>
      <v-card-text>
        <data-table-extended
          v-if="item.products.length"
          :items="item.products"
          :headers="productsHeaders"
          :server-items-length="item.products.length"
        />
        <div v-else>There are no related products for this source yet.</div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import InformationSource from 'Models/InformationSource';
import ListingTable from 'Components/ListingTable';
import ItemInfoTable from 'Components/ItemInfoTable';
import { HDR_SIMPLE_TABLE } from 'Lib/enums/DataTableHeaders';
import { TableHeaderActions } from 'Lib/TypeDefs';
import { WIMS_URL } from '../../../project.config';
import DataTableExtended from 'Components/DataTableExtended';

export default {
  name: 'InformationSource',
  components: { DataTableExtended, ItemInfoTable, ListingTable },
  props: {
    item: InformationSource,
  },
  computed: {
    productsHeaders() {
      return [
        ...HDR_SIMPLE_TABLE,
        new TableHeaderActions({
          text: '',
          actions: ['openUrl'],
          width: '1%',
          cellClass: 'no-wrap',
          actionsData: {
            openUrl: {
              icon: 'mdi-open-in-new',
              color: 'info',
              hint: 'Open URL in new tab',
              callback: (item) => {
                window.open(`${WIMS_URL}/products/${item.id}`, '_blank');
              },
            },
          },
        }),
      ];
    },
    snapshotsFilter() {
      return { 'informationSource.id': this.item.id };
    },
    module() {
      return this.Modules.MODULE_INFORMATION_SOURCES;
    },
  },
  methods: {},
  created() {},
};
</script>
