<template>
  <v-simple-table>
    <tbody>
      <tr v-for="(prop, i) in props" :key="i">
        <td>{{ prop.label }}</td>
        <td>
          <div v-if="prop.type === 'link'">
            <a v-bind="prop.props">{{ formatValue(prop) }}</a>
            <v-btn x-small text class="ml-2 square-button" :href="prop.props.href" target="_blank" elevation="3">
              <v-icon small title="Open in new tab">mdi-open-in-new</v-icon>
            </v-btn>
          </div>
          <span v-else>{{ formatValue(prop) }}</span>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import { getProp } from 'Lib/Utils';
import moment from 'moment-timezone';
import { MODULES_MAP } from 'Lib/enums/ApiModulesMap';

export default {
  name: 'ItemInfoTable',
  props: {
    item: Object,
    module: String,
  },
  computed: {
    props() {
      if (typeof this.item.getPropsList === 'function') {
        return this.item.getPropsList(this.item, this.$router) || [];
      } else if (this.model) {
        return new this.model().getPropsList();
      }
      return [];
    },
    moduleConf() {
      return MODULES_MAP[this.module];
    },
    model() {
      return this.moduleConf?.model;
    },
  },
  methods: {
    formatValue(prop) {
      const { id, type, defaultValue } = prop;
      const value = getProp(this.item, id);
      if (type === 'switch') {
        return ['No', 'Yes'][+!!value];
      }
      if (type === 'unix_timestamp') {
        const { format } = prop;
        const { timezone } = this.item;
        const time = moment().unix(value);
        if (timezone && !/utc|gmt/i.test(timezone)) {
          time.tz(this.item.timezone);
        }
        return time.format(format || 'HH:mm, MMMM DD, YYYY');
      }
      return typeof value === 'undefined' || value === null ? defaultValue : value;
    },
  },
};
</script>

<style scoped></style>
