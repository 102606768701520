module.exports = {
  api: {
    host: 'https://wims2.winglegroup.com',
    suffix: '.jsonld',
    auth: '/login_check',
    refresh: '/api/token/refresh',
  },
  recaptcha: {
    siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
  },
  WIMS_URL: process.env.VUE_APP_WIMS_URL,
};
