<!--suppress HtmlUnknownAttribute -->
<template>
  <div>
    <search-bar
      v-if="!disableSearch && model && model.searchConf"
      :config="model.searchConf"
      persist
      :module="module"
      @submit="applyFilters"
    />
    <div v-if="total !== null" class="caption text-center py-2">
      Found Results:
      <v-progress-circular v-if="loading" indeterminate size="16" width="2" />
      <span v-else style="font-weight: bold">{{ total }}</span>
    </div>
    <data-table-extended
      :items="items"
      :headers="headers"
      :items-per-page="itemsPerPage"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :loading="loading"
      :server-items-length="total"
      :per-page-opts="perPageOpts"
      hide-default-footer
      dense
      @click:row="navToItem"
      @update:options="setOptions"
    />
    <pager :page="page" :items-per-page="itemsPerPage" :total="total" @change="setPage" />
  </div>
</template>

<script>
import Pager from 'Components/Pager';
import SearchBar from 'Components/Search/SearchBar';
import DataTableExtended from 'Components/DataTableExtended';
import { MODULES_MAP } from 'Lib/enums/ApiModulesMap';
import { getDebouncer } from 'Lib/Utils';

export default {
  name: 'ListingTable',
  components: { DataTableExtended, Pager, SearchBar },
  props: {
    module: {
      type: String,
      required: true,
    },
    perPageOpts: {
      type: Array,
      default: () => [30, 100, 200],
    },
    disableSearch: {
      type: Boolean,
      default: false,
    },
    permanentFilters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      page: 1,
      collection: null,
      itemsPerPage: 30,
      loading: false,
      filters: {},
      sort: {},
      hasBeenLoaded: false,
    };
  },
  computed: {
    noNav() {
      return this.moduleConf?.noNav || false;
    },
    total() {
      return this.collection?.total || null;
    },
    items() {
      return this.collection?.items;
    },
    moduleConf() {
      return MODULES_MAP[this.module];
    },
    model() {
      return this.moduleConf?.model;
    },
    headers() {
      if (this.model) {
        return this.model.getHeaders();
      }
      return [
        {
          text: 'ID',
          value: 'id',
          width: '1%',
        },
        {
          text: 'Name',
          value: 'name',
        },
      ];
    },
    sortBy() {
      return this.sort?.by || null;
    },
    sortDesc() {
      return this.sort?.desc || null;
    },
    fullFilter() {
      const { itemsPerPage, page } = this;
      return { ...this.filters, itemsPerPage, page };
    },
  },
  created() {
    this.init();
  },
  watch: {
    module() {
      this.init();
    },
  },
  methods: {
    debounce: getDebouncer(200),
    init() {
      this.hasBeenLoaded = this.disableSearch;
      if (this.model) {
        const defaultFilters = this.model?.getDefaultFilters() || {};
        const defaultSort = this.model?.getDefaultSort() || {};
        this.filters = defaultFilters;
        this.sort = defaultSort;
      }
    },
    getSortProp(by) {
      const header = this.headers.find((el) => el.value === by);
      if (header) {
        return `order[${header.sortProp || header.prop || header.value || by}]`;
      }
      return by;
    },
    async getData() {
      if (!this.module) {
        return;
      }
      this.loading = true;
      this.collection = null;
      this.collection = await this.$api.getApiCollection(this.module, this.buildFilters());
      this.loading = false;
      this.hasBeenLoaded = true;
    },
    buildFilters() {
      const { sort, fullFilter: filters, permanentFilters } = this;
      const sorting = {};
      if (sort?.by) {
        const sortProp = this.getSortProp(sort.by);
        sorting[sortProp] = sort.desc ? 'desc' : 'asc';
      }
      return { ...filters, ...sorting, ...permanentFilters };
    },
    setPerPage(number) {
      this.itemsPerPage = number;
      this.$emit('update:items-per-page', number);
      this.page = 1;
      this.getData();
    },
    setPage(number) {
      this.page = number;
      this.$emit('update:page', number);
      this.getData();
    },
    setOptions(opts) {
      const {
        sortBy: [by],
        sortDesc: [desc],
        itemsPerPage,
        page,
      } = opts || {};
      this.page = page;
      this.itemsPerPage = itemsPerPage;
      this.$emit('update:items-per-page', itemsPerPage);
      this.$emit('update:page', page);
      this.sort = { by: by || null, desc: desc || null };
      if (this.hasBeenLoaded) {
        this.getData();
      }
    },
    navToItem(item) {
      if (this.noNav) {
        return;
      }
      const { id, moduleName: module } = item;
      this.$router.push({ name: 'ModuleItem', params: { module, id } });
    },
    applyFilters(filters) {
      this.filters = filters;
      console.log(filters);
      this.debounce(this.getData);
    },
  },
};
</script>
