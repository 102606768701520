<template>
  <v-list nav dense>
    <v-list-item-group>
      <v-list-item to="/">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Home</v-list-item-title>
      </v-list-item>
      <v-divider class="mb-1" />
      <v-list-item
        v-for="(link, i) in modulesLinks"
        :to="link.module !== null && { name: 'ModuleListing', params: { module: link.module } }"
        :href="link.href"
        :target="link.target || '_self'"
        :key="i"
        active-class=""
      >
        <v-list-item-icon>
          <v-icon>{{ link.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ link.title }}</v-list-item-title>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { MODULES_MAP } from 'Lib/enums/ApiModulesMap';

const ICON_DEFAULT = 'mdi-folder';

export default {
  name: 'SidebarMenu',
  computed: {
    modulesLinks() {
      const entries = Object.entries(MODULES_MAP).reduce((acc, [id, data]) => {
        if (data.excludeFromMenu) {
          return acc;
        }
        const { title, icon, menuTitle } = data;
        acc.push({
          module: id,
          title: menuTitle || title || id,
          icon: icon || ICON_DEFAULT,
        });
        return acc;
      }, []);
      entries.push({
        module: null,
        title: 'Wayback Machine',
        icon: 'mdi-history',
        href: 'http://wayback_machine.winglegroup.com/',
        target: '_blank',
      });
      return entries;
    },
  },
};
</script>
