<template>
  <component v-if="componentName" :is="componentName" v-bind="{ item, header }" />
  <div v-else>
    {{ value }}
  </div>
</template>

<script>
import CellName from 'Components/TableCells/CellName';
import CellSwitch from 'Components/TableCells/CellSwitch';
import CellActions from 'Components/TableCells/CellActions';
import CellDate from 'Components/TableCells/CellDate';
import CellPopupTable from 'Components/TableCells/CellPopupTable';

export default {
  name: 'Cell',
  components: { CellDate, CellActions, CellSwitch, CellName, CellPopupTable },
  props: ['item', 'header'],
  computed: {
    componentName() {
      const { value } = this.header;
      return (
        {
          __name: 'cell-name',
          __switch: 'cell-switch',
          __actions: 'cell-actions',
          __date: 'cell-date',
          __popupTable: 'cell-popup-table',
        }[value] || null
      );
    },
    value() {
      const { prop, value } = this.header;
      return this.item[prop] || this.item[value] || null;
    },
  },
};
</script>
