export default class BaseModel {
  props = {};
  _raw = {};
  _defaults = {};
  #proxy;
  #moduleName;
  static MODULE = null;
  static LD = true;

  static searchConf = null;
  static getHeaders = () => [
    {
      text: 'ID',
      value: 'id',
      width: '1%',
    },
    {
      text: 'Name',
      value: 'name',
    },
  ];
  static getDefaultFilters = () => ({});
  static getDefaultSort = () => ({ by: null, desc: null });

  getPropsList = () => {
    return this.constructor.getHeaders().reduce((acc, header) => {
      const { value, text, prop, defaultValue } = header;
      if (!['__actions'].includes(value)) {
        acc.push({ id: prop || value, label: text, defaultValue });
      }
      return acc;
    }, []);
  };

  constructor(props = {}) {
    this.setProps(props);
    this.#proxy = new Proxy(this, {
      get: (target, p) => {
        if (p in target) {
          return target[p];
        }
        // eslint-disable-next-line no-prototype-builtins
        if (target.props.hasOwnProperty(p)) {
          const val = target.props[p];
          return typeof val === 'undefined' ? target._defaults[p] : val;
        }
        return target._defaults[p];
      },
      set: (target, p, value) => {
        if (p in target.props) {
          target.props[p] = value;
        } else {
          target[p] = value;
        }
        return true;
      },
    });
    this.#moduleName = this.constructor.MODULE;
    return this.#proxy;
  }
  get raw() {
    return this._raw;
  }
  get _proxy() {
    return this.#proxy;
  }
  get moduleName() {
    return this.#moduleName;
  }
}

BaseModel.prototype.setProps = function (props = {}) {
  this._raw = JSON.parse(JSON.stringify(props || {}));
  this.props = { ...props };
};

BaseModel.prototype.getSearchConf = function () {
  return this.constructor.searchConf;
};
