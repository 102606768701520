<template>
  <v-container>
    <listing-table :module="module" />
  </v-container>
</template>

<script>
import ListingTable from 'Components/ListingTable';
export default {
  name: 'ModuleListing',
  components: { ListingTable },
  computed: {
    module() {
      return this.$route.params.module;
    },
  },
};
</script>
