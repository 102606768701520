<template>
  <v-app v-if="!loading" id="app">
    <template v-if="!isLoggedIn">
      <router-view name="public" />
    </template>
    <main v-else class="app-wrapper">
      <v-app-bar color="white" dense light elevate class="sticky sticky-top" style="z-index: 1">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="blue" />
        <v-spacer />
        <main-title />
        <v-spacer />
        <v-menu left bottom min-width="160">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="blue">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="logOut">
              <v-list-item-icon>
                <v-icon color="blue">mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Log Out</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
      <div>
        <v-navigation-drawer v-model="drawer" app temporary bottom dark>
          <sidebar-menu />
        </v-navigation-drawer>
        <router-view />
      </div>
    </main>
  </v-app>
</template>

<script>
import SidebarMenu from 'Components/SidebarMenu';
import MainTitle from 'Components/MainTitle';

function isApiUrl(url) {
  if (!url) {
    return false;
  }
  try {
    const _url = new URL(url);
    return _url.host === 'wims2.winglegroup.com' && /^\/api\//i.test(_url.pathname);
  } catch (e) {
    return false;
  }
}

export default {
  name: 'App',
  components: { MainTitle, SidebarMenu },
  data() {
    return {
      drawer: false,
      loading: true,
    };
  },
  watch: {
    loading(state) {
      const html = document.querySelector('html');
      if (html) {
        html.classList[state ? 'add' : 'remove']('loading');
      }
    },
  },
  created() {
    this.$store.subscribeAction((act) => {
      if (act.type === 'checkAuthToken') {
        const { refresh_token } = this.$store.state;
        this.$store.commit('clearRefreshToken');
        this.$client.cancel();
        this.$client
          .refreshAuthToken(refresh_token)
          .then((data) => {
            if (data) {
              this.logIn(data);
            } else {
              this.logOut();
            }
          })
          .catch(() => {
            this.logOut();
          });
      }
    });
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'logout') {
        if (this.$route.path !== '/') {
          this.$router.push('/');
        }
      }
    });
    this.$http.interceptors.response.use(undefined, (err) => {
      return new Promise(() => {
        if (err.response) {
          const { response, config } = err;
          console.log(isApiUrl(config?.url));
          if (response?.status === 401 && config && !err.config.__isRetryRequest && isApiUrl(config?.url)) {
            this.$client.cancel();
            if (this.$store.state.refresh_token) {
              this.$store.dispatch('checkAuthToken').then();
            } else {
              this.logOut();
            }
          }
        }
        throw err;
      });
    });
    this.loadUserProfile();
    // this.$client
    //   .get('https://wims2.winglegroup.com/api/information_source_snapshots', {
    //     params: { 'informationSource.id': 7110 },
    //   })
    //   .then(console.log);
    // setTimeout(() => {
    //   const token =
    //     'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImN0eSI6IkpXVCJ9.eyJpYXQiOjE2Mzc0NDgwMDIsImV4cCI6MTYzNzQ1MTYwMiwicm9sZXMiOlsiUk9MRV9BUElfVVNFUiIsIlJPTEVfU1RBRkYiLCJST0xFX0NPTVBBTklFU19FRElUIiwiUk9MRV9DT01QT05FTlRTIiwiUk9MRV9DT01QQU5JRVMiLCJST0xFX1BST0RVQ1RTIiwiUk9MRV9CUkFORF9FRElUIiwiUk9MRV9VU0VSIiwiUk9MRV9BRE1JTiIsIlJPTEVfQ09OVEFDVF9FRElUIiwiUk9MRV9UUklBTF9VU0VSIiwiUk9MRV9QQVRFTlRTIl0sInVzZXJuYW1lIjoiZGV2bWFrZWV2QGdtYWlsLmNvbSIsImlkIjo0MDIsImhvc3QiOiJodHRwOlwvXC93aW1zLWRldi5hcmtvdGlrLmNvbS51YSJ9.jS-EGD0Hwb2LyPK6O1Am29iZBSItI82vEEoWZV27EcGqNTINll-oOihmEcspk7P673gWE26jN3j3eS2ts9ht377uQZAC1G-KpuLMg0GrrarxcjThWOmWCfNg6MqJnIb21BVe3osqtsrTMye4xOciIEsi546_2cxvYPWKcjdWcJu4C8-Z9xlyi9NFnacKevGgn0M9ENzXxuJMb3khnAbjrE0vthne3FKglmc_bJAkQOu5aFyALDAxRqXXT-ctw5eRn9PbmlpKhID-jZSXmbRP5jH21WVhIr-IDA6K3w2phX2GzuyRO5RCdGrg1I9gNi11MFJdkJdx6avSw1GAdjRMWP8k_xpXmXBOZYuyQt9Y1bbyeKo8KKH1IFNRVrooVVN5_zfPpEQAVB-atX02t-_iZiHodyfYUnjSyB409nsxDSNsuJ_5fEMiRIk5JRJ1B70qBL591xCzGSf3kPh_P4IGqyMDm0U8RMyXH6Sf9fPNnfi2b9mC0pUw_hpu6jOjohhdX1RMozpWQGPLhJKnsIKkcpDUQXl8VG7c8-xe3jA_v7HRw03swAuleiINdXwD0dHbUPlof_gFujp_NySR_-xvutY1wt4Gs1PMN-g9ske4xOP_eW6tgIhDYU4NJQ0imOIfSvFCOuMyDDeOhmqRQ39DKwZP4jVK_HipIwtO6Z17-z4';
    //   const refresh_token =
    //     '29f7edf29ec64b72082555ac15b3c7a3a6935f439a9409a8e3372acb2e278bac3f81a421db60307b4fa923df55742cad9d7a110f696be06b499da3b1f230f271';
    //   this.$store.dispatch('login', { success: true, token, refresh_token, user: this.$store.state.user });
    // }, 3e3);
  },
  methods: {
    loadUserProfile() {
      this.$api.getUserProfile().then(
        (profile) => {
          this.$store.commit('setUser', profile);
          this.loading = false;
        },
        (err) => {
          console.error(err);
          this.loading = false;
        }
      );
    },
  },
};
</script>
