<template>
  <div>
    <template v-for="(action, i) in actions">
      <v-btn
        :key="i"
        small
        text
        class="my-1 square-button"
        :class="{ 'ml-1': i > 0 }"
        elevation="2"
        :title="actionsData[action].hint"
        @click.stop="triggerAction(action, item)"
      >
        <v-icon :color="actionsData[action].color">{{ actionsData[action].icon }}</v-icon>
      </v-btn>
    </template>
  </div>
</template>

<script>
import { TableHeaderActions } from 'Lib/TypeDefs';

export default {
  name: 'CellActions',
  props: {
    item: Object,
    header: TableHeaderActions,
  },
  computed: {
    actions() {
      return this.header.actions || [];
    },
    actionsData() {
      const { actionsData } = this.header;
      return {
        update: { icon: 'mdi-pencil', color: 'info', hint: 'Update item' },
        remove: { icon: 'mdi-delete', color: 'red', hint: 'Remove item' },
        ...(actionsData || {}),
      };
    },
  },
  methods: {
    triggerAction(action, item) {
      const { actionsData, header } = this;
      if (action === 'update') {
        // todo: add update action
      } else if (action === 'remove') {
        // todo: add remove action
      } else {
        const { callback } = actionsData[action];
        if (typeof callback === 'function') {
          callback(item, header);
        }
      }
    },
  },
};
</script>
