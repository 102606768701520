import BaseModel from 'Models/BaseModel';
import { MODULE_INFORMATION_SOURCE_SNAPSHOTS } from 'Lib/enums/ApiModules';
import { DataTableHeader, TableHeaderDate, TableHeaderName /*, TableHeaderActions*/ } from 'Lib/TypeDefs';

/**
 * @class InformationSourceSnapshot
 * @property {string} originalName
 * @property {string} capturedAt
 * @property {string} createdAt
 * @property {number} version
 */
export default class InformationSourceSnapshot extends BaseModel {
  static MODULE = MODULE_INFORMATION_SOURCE_SNAPSHOTS;

  /**
   * @type {SearchModel}
   */
  static searchConf = null;

  static getHeaders = () => {
    return [
      new TableHeaderName({
        text: 'Original Name',
        prop: 'originalName',
      }),
      new TableHeaderDate({
        text: 'Captured',
        prop: 'capturedAt',
      }),
      new DataTableHeader({
        text: 'Version',
        value: 'version',
        width: '1%',
      }),
      // new TableHeaderActions({
      //   text: '',
      //   actions: ['openUrl'],
      //   width: '1%',
      //   cellClass: 'no-wrap',
      //   actionsData: {
      //     openUrl: {
      //       icon: 'mdi-open-in-new',
      //       color: 'info',
      //       hint: 'Open URL in new tab',
      //       callback: (item) => {
      //         window.open(item.url, '_blank');
      //       },
      //     },
      //   },
      // }),
    ];
  };

  static getDefaultSort = () => ({ by: null, desc: true });
}
