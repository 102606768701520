<template>
  <span>{{ formatted }}</span>
</template>

<script>
import moment from 'moment';

export default {
  name: 'CellDate',
  props: {
    item: Object,
    header: Object,
  },
  computed: {
    value() {
      return this.item[this.header.prop] || null;
    },
    format() {
      return this.header.format || 'YYYY-MM-DD HH:mm';
    },
    formatted() {
      return moment(this.value).format(this.format) || this.header.placeholder || '(none)';
    },
  },
};
</script>
