import BaseModel from 'Models/BaseModel';
import { MODULE_NEWSLETTER_EMAILS_DOMAIN } from 'Lib/enums/ApiModules';
import SearchModel from 'Models/SearchModel';
import FilterTypes from 'Components/Search/FilterTypes';
import FieldTypes from 'Components/Search/FieldTypes';
import { DataTableHeader } from 'Lib/TypeDefs';

/**
 * @class NewsletterEmailDomain
 * @property {string} domain
 */
export default class NewsletterEmailDomain extends BaseModel {
  static MODULE = MODULE_NEWSLETTER_EMAILS_DOMAIN;
  static LD = true;

  /**
   * @type {SearchModel}
   */
  static searchConf = new SearchModel(
    [
      {
        id: 'domain',
        title: 'Domain',
        filter: FilterTypes.FILTER_SINGLE,
        type: FieldTypes.FIELD_TEXT,
      },
    ],
    'domain'
  );

  static getHeaders = () => {
    return [
      new DataTableHeader({
        text: 'Domain',
        value: 'domain',
        sortable: true,
        sortProp: 'order[domain]',
      }),
    ];
  };
}
