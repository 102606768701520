export const MODULE_NEWSLETTER_EMAILS = 'newsletter_emails';
export const MODULE_NEWSLETTER_EMAILS_DOMAIN = 'newsletter_email_domains';
export const MODULE_INFORMATION_SOURCES = 'information_sources';
export const MODULE_INFORMATION_SOURCE_SNAPSHOTS = 'information_sources_snapshots';
export const MODULE_NEWS = 'news';

export default {
  MODULE_NEWSLETTER_EMAILS,
  MODULE_NEWSLETTER_EMAILS_DOMAIN,
  MODULE_INFORMATION_SOURCES,
  MODULE_INFORMATION_SOURCE_SNAPSHOTS,
  MODULE_NEWS,
};
