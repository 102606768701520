/**
 * @typedef {Object} FilterConf
 * @property {string} id
 * @property {string} title
 * @property {string} [label]
 * @property {string} filter
 * @property {string} type
 * @property {Object} [attrs]
 * @property {Object} [events]
 * @property {Object} [items]
 * @property {function} [getItems]
 * @property {Object} [rangeConf]
 */

/**
 * @class SearchModel
 * @property {FilterConf[]} filtersList
 * @property {string} [searchbarProp='name']
 */
export default class SearchModel {
  /**
   * @param {FilterConf[]} filtersList
   * @param {string} [searchbarProp='name']
   */
  constructor(filtersList, searchbarProp = 'name') {
    this.filtersList = filtersList;
    this.searchbarProp = searchbarProp;
  }
}
