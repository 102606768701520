<template>
  <component v-if="component" :is="component" v-model="innerValue" v-bind="attrs" v-on="on" />
</template>

<script>
import FieldTypes from 'Components/Search/FieldTypes';
import InputDate from 'Components/Search/Inputs/InputDate';
import { VSelect, VTextField } from 'vuetify/lib/components';
import FilterSwitch from 'Components/Search/FilterSwitch';

export default {
  name: 'FieldSingle',
  components: { FilterSwitch, InputDate, VTextField, VSelect },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    value: {},
  },
  computed: {
    component() {
      switch (this.config.type) {
        case FieldTypes.FIELD_TEXT:
        case FieldTypes.FIELD_NUMBER:
        default:
          return 'v-text-field';
        case FieldTypes.FIELD_DATE:
          return 'input-date';
        // case FieldTypes.FIELD_AUTOCOMPLETE:
        //   return 'input-autocomplete';
        case FieldTypes.FIELD_SELECT:
          return 'v-select';
        case FieldTypes.FIELD_SWITCH:
          return 'filter-switch';
      }
    },
    attrs() {
      const { config } = this;
      const { attrs, type, label } = config;
      const fieldAttrs = {};
      if (type === FieldTypes.FIELD_NUMBER) {
        fieldAttrs.type = 'number';
      }
      if (type === FieldTypes.FIELD_SELECT) {
        const { items } = this.config;
        fieldAttrs.items = items;
      }
      return {
        ...(attrs || ''),
        ...fieldAttrs,
        dense: true,
        outlined: true,
        hideDetails: true,
        clearable: true,
        autofocus: true,
        label,
      };
    },
    on() {
      const { events } = this.config;
      return { ...events };
    },
    innerValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('change', value);
      },
    },
  },
};
</script>
