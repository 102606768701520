<!--suppress HtmlUnknownAttribute -->
<template>
  <v-data-table v-bind="$attrs" v-on="$listeners">
    <template #item.__name="{ item, header }">
      <cell-name v-bind="{ item, header }" />
    </template>
    <template #item.__switch="{ item, header }">
      <cell-switch v-bind="{ item, header }" />
    </template>
    <template #item.__actions="{ item, header }">
      <cell-actions v-bind="{ item, header }" />
    </template>
    <template #item.__date="{ item, header }">
      <cell-date v-bind="{ item, header }" />
    </template>
    <template #item.__popupTable="{ item, header }">
      <cell-popup-table v-bind="{ item, header }" />
    </template>
    <template v-for="(slotValue, slotName) in $scopedSlots" v-slot:[slotName]="props">
      <slot :name="slotName" v-bind="props" />
    </template>
    <template #footer>
      <v-row v-if="total >= perPageOpts[0]" class="mt-2" align="center" justify="center">
        <v-spacer></v-spacer>
        <span class="grey&#45;&#45;text">Items per page</span>
        <v-menu offset-y :disabled="!total">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark text color="primary" class="ml-2" v-bind="attrs" v-on="on">
              {{ itemsPerPage }}
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(number, index) in perPageOpts"
              :key="index"
              @click="$emit('update:items-per-page', $event)"
            >
              <v-list-item-title>{{ number }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div class="mr-3">
          <span>{{ itemStart }} - {{ itemStop }} of {{ total }}</span>
        </div>
      </v-row>
    </template>
  </v-data-table>
</template>

<script>
import CellName from 'Components/TableCells/CellName';
import CellSwitch from 'Components/TableCells/CellSwitch';
import CellActions from 'Components/TableCells/CellActions';
import CellDate from 'Components/TableCells/CellDate';
import CellPopupTable from 'Components/TableCells/CellPopupTable';

export default {
  name: 'DataTableExtended',
  components: { CellPopupTable, CellDate, CellActions, CellSwitch, CellName },
  props: {
    perPageOpts: {
      type: Array,
      default: () => [30, 100, 200],
    },
  },
  computed: {
    itemStart() {
      return (this.page - 1) * this.itemsPerPage + +(this.total > 0);
    },
    itemStop() {
      return Math.min(this.itemsPerPage * this.page, this.total);
    },
    total() {
      return this.$attrs.serverItemsLength;
    },
  },
  methods: {
    setPage(number) {
      this.$emit('update:page', number);
    },
  },
};
</script>
