export default {
  methods: {
    setCounter(key, value) {
      if (!key) {
        return;
      }
      this.$store.commit('setCounter', { key, value });
    },
    /**
     * @param key
     * @return {{ value: number|null, updatedAt: number|null }}
     */
    getCounter(key) {
      return this.$store.state.counters[key] || { value: null, updatedAt: null };
    },
    getCounterValue(key) {
      return this.$store.state.counters[key]?.value || 0;
    },
    getCounterUpdateTime(key) {
      return this.$store.state.counters[key]?.updatedAt || 0;
    },
  },
};
