<template>
  <div>List</div>
</template>

<script>
export default {
  name: 'FieldList',
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
};
</script>
