var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"item.__name",fn:function(ref){
var item = ref.item;
var header = ref.header;
return [_c('cell-name',_vm._b({},'cell-name',{ item: item, header: header },false))]}},{key:"item.__switch",fn:function(ref){
var item = ref.item;
var header = ref.header;
return [_c('cell-switch',_vm._b({},'cell-switch',{ item: item, header: header },false))]}},{key:"item.__actions",fn:function(ref){
var item = ref.item;
var header = ref.header;
return [_c('cell-actions',_vm._b({},'cell-actions',{ item: item, header: header },false))]}},{key:"item.__date",fn:function(ref){
var item = ref.item;
var header = ref.header;
return [_c('cell-date',_vm._b({},'cell-date',{ item: item, header: header },false))]}},{key:"item.__popupTable",fn:function(ref){
var item = ref.item;
var header = ref.header;
return [_c('cell-popup-table',_vm._b({},'cell-popup-table',{ item: item, header: header },false))]}},_vm._l((_vm.$scopedSlots),function(slotValue,slotName){return {key:slotName,fn:function(props){return [_vm._t(slotName,null,null,props)]}}}),{key:"footer",fn:function(){return [(_vm.total >= _vm.perPageOpts[0])?_c('v-row',{staticClass:"mt-2",attrs:{"align":"center","justify":"center"}},[_c('v-spacer'),_c('span',{staticClass:"grey&#45;&#45;text"},[_vm._v("Items per page")]),_c('v-menu',{attrs:{"offset-y":"","disabled":!_vm.total},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"dark":"","text":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.itemsPerPage)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,false,49852867)},[_c('v-list',_vm._l((_vm.perPageOpts),function(number,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.$emit('update:items-per-page', $event)}}},[_c('v-list-item-title',[_vm._v(_vm._s(number))])],1)}),1)],1),_c('div',{staticClass:"mr-3"},[_c('span',[_vm._v(_vm._s(_vm.itemStart)+" - "+_vm._s(_vm.itemStop)+" of "+_vm._s(_vm.total))])])],1):_vm._e()]},proxy:true}],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }